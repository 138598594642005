<template>
  <div
    id="faq"
    class="faq-box"
  >
    <div class="faq-wrapper">
      <h1>{{ $t('home.faqTitle') }}</h1>
      <div
        v-if="isFetchingFaqs"
        class="d-flex my-2 justify-content-center"
      >
        <b-spinner variant="primary" />
      </div>

      <div
        v-if="!isFetchingFaqs"
        class="faq-categories-and-questions"
      >
        <div class="faq-categories">
          <b-button
            v-for="(faqSection, index) in faqsFetched"
            :key="index"
            block
            class="text-left faq-category"
            :variant="
              faqSection.id === selectedCategory.id ? 'primary' : 'link'
            "
            @click="changeFaqCategory(faqSection)"
          >
            <!-- <feather-icon icon="LockIcon" size="16" class="d-inline mr-1" /> -->
            {{ faqSection.title }}
          </b-button>
          <!-- <b-button block class="text-left faq-category" variant="primary">
            <feather-icon icon="LockIcon" size="16" class="d-inline mr-1" />
            Paiement
          </b-button> -->
        </div>
        <div class="faq-questions">
          <div
            v-if="selectedCategory"
            class="faq-question-config"
          >
            <b-button
              class="btn-icon mr-2"
              variant="primary"
            >
              <feather-icon
                icon="HelpCircleIcon"
                class="d-inline"
              />
            </b-button>
            <v-select
              v-model="selectedCategory"
              label="title"
              class="bg-light category-selector"
              :options="faqsFetched"
            />
            <b>{{ selectedCategory.title }}</b>
          </div>
          <app-collapse
            v-if="selectedCategory"
            class="mt-2"
            accordion
            type="margin"
          >
            <app-collapse-item
              v-for="(question, index) in selectedCategory.faqs"
              :key="index"
              :title="question.title"
            >
              <span v-html="question.response" />
            </app-collapse-item>
            <!--
            <app-collapse-item title="Accordion Item 2">
              Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant.
              Tootsie roll sweet candy bear claw chupa chups lollipop toffee.
              Macaroon donut liquorice powder candy carrot cake macaroon
              fruitcake. Cookie toffee lollipop cotton candy ice cream dragée
              soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart.
              Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé
              icing caramels. Chocolate cake icing ice cream macaroon pie
              cheesecake liquorice apple pie.
            </app-collapse-item>

            <app-collapse-item title="Accordion Item 3">
              Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant.
              Tootsie roll sweet candy bear claw chupa chups lollipop toffee.
              Macaroon donut liquorice powder candy carrot cake macaroon
              fruitcake. Cookie toffee lollipop cotton candy ice cream dragée
              soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart.
              Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé
              icing caramels. Chocolate cake icing ice cream macaroon pie
              cheesecake liquorice apple pie.
            </app-collapse-item>

            <app-collapse-item title="Accordion Item 4">
              Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant.
              Tootsie roll sweet candy bear claw chupa chups lollipop toffee.
              Macaroon donut liquorice powder candy carrot cake macaroon
              fruitcake. Cookie toffee lollipop cotton candy ice cream dragée
              soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart.
              Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé
              icing caramels. Chocolate cake icing ice cream macaroon pie
              cheesecake liquorice apple pie.
            </app-collapse-item> -->
          </app-collapse>
          <!-- <div>
            <div header-tag="header" role="tab">
              <b-button
                class="accordion-btn-shad text-left"
                block
                v-b-toggle.my-accordion
                variant="light"
                >Accordion 1</b-button
              >
            </div>
            <b-collapse ref="accor" accordion="my-accordion" role="tabpanel">
              <b-card>
                <b-card-text
                  >I start opened because <code>visible</code> is
                  <code>true</code></b-card-text
                >
                <b-card-text>iznfzenfoz</b-card-text>
              </b-card>
            </b-collapse>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BForm,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BInputGroupPrepend,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BCardText,
  BModal,
  BCardImg,
  BSpinner,
  BCarousel,
  BCarouselSlide,
  BAlert,
  BCollapse,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Mod
import faqsStoreModule from '@/store/faq'
import { mapActions } from 'vuex'
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,

    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormGroup,
    BButton,
    BModal,
    BCarousel,
    BCarouselSlide,
    BAlert,
    BCollapse,
    BSpinner,

    vSelect,
  },
  data() {
    return {
      selectedCategory: null,
      isFetchingFaqs: false,
      faqsFetched: [],
      faqs: [],
      showablefaqs: [],
    }
  },
  watch: {
    selectedCategory: {
      handler(val, old) {},
    },
  },
  mounted() {
    this.fetchFaqs()
  },
  setup() {
    const requiredStoreModules = [
      { path: 'faqSections', module: faqsStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  methods: {
    ...mapActions('faqSections', {
      action_fetchFaqSections: 'fetchFaqSections',
    }),
    changeFaqCategory(faqCategory) {
      this.selectedCategory = faqCategory
    },
    fetchFaqs() {
      this.isFetchingFaqs = true
      this.action_fetchFaqSections()
        .then(response => {
          // console.log('✅ Successfully fetched FAQS ✅');
          // const others = {
          //   id: response.data.data[response.data.data.length - 1].id + 1,
          //   title: 'Autres',
          //   faqs: [
          //     {
          //       id: Math.random() * 1000,
          //       title: 'Autre question 1',
          //       response: 'Autre réponse 1',
          //       faq_section_id:
          //         response.data.data[response.data.data.length - 1].id + 1,
          //     },
          //     {
          //       id: Math.random() * 1000,
          //       title: 'Autre question 2',
          //       response: 'Autre réponse 2',
          //       faq_section_id:
          //         response.data.data[response.data.data.length - 1].id + 1,
          //     },
          //   ],
          // };
          // this.faqsFetched = [...response.data.data, others];

          this.faqsFetched = response.data.data.map(category => ({
            ...category, // Copy all properties from the original category
            faqs: category.faqs.filter(faq => faq.can_show && category.faqs.length > 0), // Filter the faqs array
          }))

          console.log('faqsFetched:::', this.faqsFetched)

          this.selectedCategory = this.faqsFetched[0]
          console.log('selectedCategory:::', this.selectedCategory)
          /* for (let i = 0; i < selectedCategory.faqs.length; i++) {
           if(selectedCategory.faqs[i].can_show)
          } */
          // console.log(response.data.data);
          this.isFetchingFaqs = false
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur survenue lors de la récupération de la FAQ.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.isFetchingFaqs = false
        })
    },
  },
}
</script>
<style scoped>
.faq-box {
  background-color: #e8f1ea;
}
.faq-wrapper {
  background-color: #e8f1ea;
  padding: 3em 3em;
  max-width: 1200px;
  margin: 0 auto;
}
.faq-wrapper h1 {
  text-align: center;
}

.faq-categories-and-questions {
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin: 1em 0 3em 0;
}
.faq-categories {
  /* width: 300px; */
  display: none;
  flex-direction: column;
}
.faq-questions {
  flex: 1;
}
.faq-question-config {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.faq-questions .category-selector {
  /* min-width: 100px; */
  width: 100%;
  margin-top: 1em;
  flex: 1;
}
/* ::v-deep .vs__selected {
  white-space: nowrap;
} */
::v-deep .vs__selected-options {
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
}
.faq-questions b {
  display: none;
}

.accordion-btn-shad {
  border: #c3cdc66e;
  box-shadow: 0px 3px 7px #00000010;
}

/* MEDIA QUERIES */
@media (min-width: 576px) {
  .faq-question-config {
    flex-direction: row;
    justify-content: flex-start;
  }
  .faq-questions .category-selector {
    margin-top: 0px;
  }
  .faq-categories-and-questions {
    margin: 3em 0 3em 0;
  }
}
@media (min-width: 768px) {
  .faq-questions .category-selector {
    display: none;
  }
  .faq-questions b {
    display: inline;
  }
  .faq-categories {
    display: flex;
    width: 260px;
  }
  .faq-categories-and-questions {
    flex-direction: row;
    margin: 3em 0 3em 0;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
}

.enforce-button {
  padding: 0.486rem 0.5rem;
}
</style>
